.App {
  background: black;
  color: white;
  font-size: 16px;
  display: flex;
  height: 100vh;
  align-items: center;
  place-content: center;
  font-style: italic;
}

.meta {
  width: 300px;
  padding: 2em;
}

.spotify {
  font-size: 10px;
  color: gray;
}

.image {
  flex-shrink: 1;
  padding: 2em;
  width: 500px;
}

.image img {
  width: 100%;
}

@media (max-width: 700px) {
  .App {
    flex-direction: column;
    width: 100vw;
  }

  .meta {
    width: auto;
  }

  .image {
    padding: 0;
    margin: 2em;
    width: auto;
  }
}
